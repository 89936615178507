import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import Button from 'components/buttons/Button';
import ArrowIcon from 'components/icons/ArrowIcon';

export const SliderNav = ({ slider = {} }) => {
    const [css] = useStyletron();

    const buttonStyles = {
        backgroundColor: 'transparent',
        borderRadius: '100px',
        marginTop: 'auto',
        padding: '10px 0 4px',
        width: '40px',
        height: '40px',
        marginRight: '8px',
    };

    return (
        <div className={css({ display: 'flex', cursor: 'pointer' })}>
            <Button $style={buttonStyles} onClick={() => slider.slideToPrev()}>
                <ArrowIcon $style={{ transform: 'rotate(-180deg)' }} />
            </Button>
            <Button $style={buttonStyles} onClick={() => slider.slideToNext()}>
                <ArrowIcon />
            </Button>
        </div>
    );
};

SliderNav.propTypes = {
    slider: PropTypes.shape({
        slideToNext: PropTypes.func,
        slideToPrev: PropTypes.func,
    }),
};
